@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../public/fonts/Brandon_blk.otf') format('opentype'),
    url('../public/fonts/Brandon_blk_it.otf') format('opentype');
  font-weight: 650;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../public/fonts/Brandon_bld.otf') format('opentype'),
    url('../public/fonts/Brandon_bld_it.otf') format('opentype');
  font-weight: 550;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../public/fonts/Brandon_med.otf') format('opentype'),
    url('../public/fonts/Brandon_med_it.otf') format('opentype');
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../public/fonts/Brandon_reg.otf') format('opentype'),
    url('../public/fonts/Brandon_reg_it.otf') format('opentype');
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../public/fonts/Brandon_light.otf') format('opentype'),
    url('../public/fonts/Brandon_light_it.otf') format('opentype');
  font-weight: 250;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('../public/fonts/Brandon_thin.otf') format('opentype'),
    url('../public/fonts/Brandon_thin_it.otf') format('opentype');
  font-weight: 150;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Black.otf') format('opentype'),
    url('../public/fonts/Inter-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-ExtraBold.otf') format('opentype'),
    url('../public/fonts/Inter-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Bold.otf') format('opentype'),
    url('../public/fonts/Inter-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-SemiBold.otf') format('opentype'),
    url('../public/fonts/Inter-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Medium.otf') format('opentype'),
    url('../public/fonts/Inter-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Regular.otf') format('opentype'),
    url('../public/fonts/Inter-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Light-BETA.otf') format('opentype'),
    url('../public/fonts/Inter-LightItalic-BETA.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-ExtraLight-BETA.otf') format('opentype'),
    url('../public/fonts/Inter-ExtraLightItalic-BETA.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Thin-BETA.otf') format('opentype'),
    url('../public/fonts/Inter-ThinItalic-BETA.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Black.ttf') format('opentype'),
    url('../public/fonts/Montserrat-BlackItalic.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-ExtraBold.ttf') format('opentype'),
    url('../public/fonts/Montserrat-ExtraBoldItalic.ttf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Bold.ttf') format('opentype'),
    url('../public/fonts/Montserrat-BoldItalic.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-SemiBold.ttf') format('opentype'),
    url('../public/fonts/Montserrat-SemiBoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Medium.ttf') format('opentype'),
    url('../public/fonts/Montserrat-MediumItalic.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Regular.ttf') format('opentype'),
    url('../public/fonts/Montserrat-Italic.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Light.ttf') format('opentype'),
    url('../public/fonts/Montserrat-LightItalic.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-ExtraLight.ttf') format('opentype'),
    url('../public/fonts/Montserrat-ExtraLightItalic.ttf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Thin.ttf') format('opentype'),
    url('../public/fonts/Montserrat-ThinItalic.ttf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

.full-container {
  max-width: 3500px;
  width: 100%;
  margin: 0 auto;
}

.app-container {
  max-width: 1728px;
  width: 100%;
}

.premium-slider > .swiper {
  padding-bottom: 50px !important;

  @media screen and (max-width: 639px) {
    padding-bottom: 50px !important;
  }
}

.home-premium-swiper {
  width: 1184px;

  @media screen and (max-width: 1279px) {
    width: 992px;
  }

  @media screen and (max-width: 1023px) {
    width: 734px;
  }

  @media screen and (max-width: 767px) {
    width: 606px;
  }

  @media screen and (max-width: 639px) {
    width: 410px;
  }

  @media screen and (max-width: 430px) {
    width: 361px;
  }

  @media screen and (max-width: 390px) {
    width: 94%;
  }
}

.home-brand-swiper {
  width: 1246px;

  @media screen and (max-width: 1279px) {
    width: 1000px;
  }

  @media screen and (max-width: 1023px) {
    width: 750px;
  }

  @media screen and (max-width: 767px) {
    width: 620px;
  }

  @media screen and (max-width: 639px) {
    width: 410px;
  }

  @media screen and (max-width: 430px) {
    width: 70%;
  }
}

.swiper-scrollbar-drag {
  background-color: #2f91ae !important;
  height: 7px !important;
  top: -1.5px !important;
}

.product-area {
  background-color: #f5f8fa;
}

.active-page-item .page-link {
  color: white !important;
  background-color: #2f91ae !important;
}
