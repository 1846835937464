.footerArea {
  background-color: #f5f8fa;
}

.title {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-bottom: 16px;

  @media screen and (max-width: 1023px) {
    font-size: 19px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 639px) {
    font-size: 17px;
    margin-bottom: 12px;
  }

  @media screen and (max-width: 430px) {
    font-size: 16px;
  }
}

.content {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;

  @media screen and (max-width: 1023px) {
    font-size: 19px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 639px) {
    font-size: 17px;
  }

  @media screen and (max-width: 430px) {
    font-size: 16px;
  }
}
