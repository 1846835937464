.topBg {
  width: 100%;
  height: 853px;
  background-image: url('/public/images/product-bg-big.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -10px;
  left: 0px;
  @media screen and (max-width: 1768px) {
    background-position: top right;
    background-size: cover;
  }

  @media screen and (max-width: 1535px) {
    height: 753px;
  }

  @media screen and (max-width: 1279px) {
    height: 653px;
  }

  @media screen and (max-width: 1023px) {
    height: 553px;
  }

  @media screen and (max-width: 767px) {
    height: 453px;
  }

  @media screen and (max-width: 639px) {
    background-image: url('/public/images/product-bg-small.png');
    height: 325px;
    background-position: top right;
    background-size: 100% 100%;
    top: 64px;
    left: 0px;
  }

  @media screen and (max-width: 430px) {
    height: 225px;
  }
}

.menuItem {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 18px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;
  cursor: pointer;
}
