.serviceSpace {
  background-color: #2f91ae;
  padding-top: 94px;
  padding-bottom: 111px;

  @media screen and (max-width: 1535px) {
    padding-top: 80px;
    padding-bottom: 92px;
  }

  @media screen and (max-width: 1279px) {
    padding-top: 67px;
    padding-bottom: 87px;
  }

  @media screen and (max-width: 1023px) {
    padding-top: 54px;
    padding-bottom: 74px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 41px;
    padding-bottom: 61px;
  }

  @media screen and (max-width: 639px) {
    padding-top: 28px;
    padding-bottom: 48px;
  }

  @media screen and (max-width: 430px) {
    padding-top: 15px;
    padding-bottom: 35px;
  }

  @media screen and (max-width: 393px) {
    padding-top: 15px;
    padding-bottom: 5px;
  }
}

.title {
  color: #fff;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;

  @media screen and (max-width: 639px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 36px;
  }

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }

  @media screen and (max-width: 430px) {
    font-size: 24px;

  }
}

.cupIcon {
  background-image: url('/public/icons/cup.svg');
  width: 62px;
  height: 62px;
  margin-bottom: 12px;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 639px) {
    width: 37px !important;
    height: 37px !important;
    margin-bottom: 6px;
  }
}

.earthIcon {
  background-image: url('/public/icons/earth.svg');
  width: 62px;
  height: 62px;
  margin-bottom: 12px;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 639px) {
    width: 37px;
    height: 37px;
    margin-bottom: 6px;
  }
}

.hatIcon {
  background-image: url('/public/icons/chef-hat.svg');
  width: 62px;
  height: 62px;
  margin-bottom: 12px;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 639px) {
    width: 37px;
    height: 37px;
    margin-bottom: 6px;
  }
}

.subtitle {
  color: #fff;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;

  @media screen and (max-width: 639px) {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

.content {
  color: #fff;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 24px;

  @media screen and (max-width: 639px) {
    display: none !important;
  }
}
