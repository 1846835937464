.premiumArea {
  background-color: #f5f8fa;
}

.title {
  color: #000;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 48px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;

  @media screen and (max-width: 1535px) {
    font-size: 44px;
    line-height: 44x;
  }

  @media screen and (max-width: 1279px) {
    font-size: 40px;
    line-height: 39x;
  }

  @media screen and (max-width: 1023px) {
    font-size: 36px;
    line-height: 34x;
  }

  @media screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 29x;
  }

  @media screen and (max-width: 639px) {
    font-size: 28px;
    line-height: 24x;
  }

  @media screen and (max-width: 430px) {
    font-size: 24px;
    line-height: 18.796px;
  }
}

.cardInfo {
  border-radius: 0px 0px 6.686px 6.686px;
  background: #b3d8df;
  width: 312px;
  height: 105.857px;
  bottom: -50px;
  padding-top: 10px;

  @media screen and (max-width: 1535px) {
    border-radius: 0px 0px 6px 6px;
    width: 300px;
    height: 95px;
    padding-top: 9px;
    bottom: -44px;
  }

  @media screen and (max-width: 1279px) {
    border-radius: 0px 0px 5.2px 5.2px;
    width: 270px;
    height: 85px;
    padding-top: 8px;
    bottom: -40px;
  }

  @media screen and (max-width: 1023px) {
    border-radius: 0px 0px 4.4px 4.4px;
    width: 240px;
    height: 75px;
    padding-top: 7px;
    bottom: -36px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0px 0px 3.6px 3.6px;
    width: 200px;
    height: 65px;
    padding-top: 6px;
    bottom: -32px;
  }

  @media screen and (max-width: 639px) {
    border-radius: 0px 0px 3px 3px;
    width: 160px;
    height: 55px;
    padding-top: 5px;
    bottom: -28px;
  }

  @media screen and (max-width: 430px) {
    width: 122px;
    height: 41px;
    padding-top: 4px;
    bottom: -20px;
    border-radius: 0px 0px 2.618px 2.618px;
  }
}

.subtitle {
  color: #2f91ae;
  font-family: 'Brandon Grotesque';
  font-size: 26.748px;
  font-style: normal;
  font-weight: 450;
  line-height: 47.43px;

  @media screen and (max-width: 1535px) {
    font-size: 24px;
    line-height: 45px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 21px;
    line-height: 40px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 18px;
    line-height: 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 15px;
    line-height: 30px;
  }

  @media screen and (max-width: 639px) {
    font-size: 13px;
    line-height: 25px;
  }

  @media screen and (max-width: 430px) {
    font-size: 10.472px;
    line-height: 18.569px;
  }
}

.viewProduct {
  color: #404a42;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 32.523px;
  font-style: normal;
  font-weight: 390;
  line-height: 25.747px;

  @media screen and (max-width: 1535px) {
    font-size: 30px;
    line-height: 24px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 26px;
    line-height: 21px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 22px;
    line-height: 18px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 15px;
  }

  @media screen and (max-width: 639px) {
    font-size: 14px;
    line-height: 12px;
  }

  @media screen and (max-width: 430px) {
    font-size: 12.733px;
    line-height: 10.08px;
  }
}
