.aboutSpace {
  background-color: #ffffff;
  @media screen and (max-width: 767px) {
    background-color: #f7f9fa;
  }
}

.bgArea {
  background-color: #b3d8df;
  width: calc(50% - 85px);
  height: 745px;

  @media screen and (max-width: 1535px) {
    width: calc(50% - 70px);
    height: 732px;
  }

  @media screen and (max-width: 1279px) {
    width: calc(50% - 50px);
    height: 700px;
  }

  @media screen and (max-width: 1023px) {
    width: calc(50% - 30px);
    height: 670px;
  }

  @media screen and (max-width: 767px) {
    width: 55%;
    height: 400px;
  }

  @media screen and (max-width: 639px) {
    height: 320px;
  }

  @media screen and (max-width: 430px) {
    height: 231px;
  }
}

.bgImg {
  background-image: url('/public/images/bg2.svg');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 737px;
  height: 594px;
  top: 69px;
  right: -3%;

  @media screen and (max-width: 1535px) {
    width: 650px;
    height: 527px;
    top: 90px;
  }

  @media screen and (max-width: 1279px) {
    width: 500px;
    height: 403px;
    top: 140px;
  }

  @media screen and (max-width: 1023px) {
    width: 380px;
    height: 306px;
    top: 160px;
    right: -2%;
  }

  @media screen and (max-width: 767px) {
    width: 400px;
    height: 322px;
    top: 55px;
    left: 20%;
  }

  @media screen and (max-width: 639px) {
    width: 300px;
    height: 243px;
    top: 60px;
    left: 15%;
  }

  @media screen and (max-width: 430px) {
    width: 236px;
    height: 190px;
    top: 33px;
  }
}

.aboutArea {
  padding-top: 80px;
  padding-left: 124px;
  padding-right: 70px;

  @media screen and (max-width: 1535px) {
    padding-top: 60px;
    padding-left: 100px;
    padding-right: 60px;
  }

  @media screen and (max-width: 1279px) {
    padding-top: 50px;
    padding-left: 75px;
    padding-right: 50px;
  }

  @media screen and (max-width: 1023px) {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 26px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 26px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 393px) {
    padding-top: 26px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.aboutTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 36px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;
  margin-bottom: 84px;

  @media screen and (max-width: 1535px) {
    font-size: 36px;
    margin-bottom: 56px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 40px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 36px;
    margin-bottom: 45px;
  }

  @media screen and (max-width: 767px) {
    font-size: 32px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 639px) {
    font-size: 28px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 430px) {
    font-size: 24px;
    margin-bottom: 1px;
  }
}

.aboutSubTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 32px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;

  @media screen and (max-width: 1535px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 28px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 26px;
  }

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }

  @media screen and (max-width: 639px) {
    font-size: 20px;
  }

  @media screen and (max-width: 430px) {
    font-size: 16px;
  }
}

.aboutContent {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 390;
  line-height: 28px;

  @media screen and (max-width: 1535px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 22px;
    line-height: 29px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 21px;
    line-height: 28.5px;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
  }

  @media screen and (max-width: 639px) {
    font-size: 19px;
    line-height: 25.5px;
  }

  @media screen and (max-width: 430px) {
    font-size: 16px;
    line-height: 24px;
  }
}
