.formArea {
  background: #f4f7f9;
}

.form {
  padding: 81px 247px 74px 165px;
  width: 859px;
  height: 822px;
  margin-left: auto;

  @media screen and (max-width: 1535px) {
    width: 100%;
    height: 735px;
    padding: 73px 170px 66px 120px;
  }

  @media screen and (max-width: 1279px) {
    padding: 65px 100px 58px 75px;
    height: 682px;
  }

  @media screen and (max-width: 1023px) {
    padding: 57px 30px 50px 30px;
    height: 630px;
  }

  @media screen and (max-width: 767px) {
    background: #fff;
    padding: 49px 107px 42px 65px;
    height: 570px;
  }

  @media screen and (max-width: 639px) {
    padding: 42px 61px 35px 42px;
    height: 467px;
  }
}

.formBg {
  background-image: url('/public/images/teas.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  height: 822px;
  width: 50%;
  right: 0px;
  top: 0px;

  @media screen and (max-width: 1535px) {
    height: 735px;
  }

  @media screen and (max-width: 1279px) {
    height: 682px;
  }

  @media screen and (max-width: 1023px) {
    height: 630px;
  }

  @media screen and (max-width: 767px) {
    position: unset;
    width: 100%;
    height: 600px;
  }

  @media screen and (max-width: 639px) {
    width: 100%;
    height: 410px;
  }

  @media screen and (max-width: 430px) {
    width: 100%;
    height: 370px;
  }
}
