.subTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 24px;
  margin-left: 15px;
  margin-bottom: 46px;

  @media screen and (max-width: 639px) {
    display: none;
  }
}

.brandButton {
  border-radius: 100px;
  background: #ededed;
  display: flex;
  width: 196px !important;
  height: 49px !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
  color: #777;
  font-family: 'Brandon Grotesque';
  font-size: 24px !important;
  font-style: normal;
  font-weight: 390;
  line-height: normal;

  @media screen and (max-width: 1535px) {
    width: 180px !important;
    gap: 9px !important;
    font-size: 23px !important;
    height: 45px !important;
  }

  @media screen and (max-width: 1279px) {
    width: 160px !important;
    gap: 8px !important;
    font-size: 22px !important;
    height: 41px !important;
  }

  @media screen and (max-width: 1023px) {
    width: 140px !important;
    gap: 7px !important;
    font-size: 20.5px !important;
    height: 37px !important;
  }

  @media screen and (max-width: 767px) {
    width: 120px !important;
    gap: 6px !important;
    font-size: 19px !important;
    height: 33px !important;
  }

  @media screen and (max-width: 639px) {
    width: 100px !important;
    gap: 5px !important;
    font-size: 17.5px !important;
    height: 30px !important;
  }

  @media screen and (max-width: 430px) {
    width: 82px !important;
    gap: 4px !important;
    font-size: 16px !important;
    height: 27px !important;
  }
}

.activeBrandButton {
  border-radius: 100px;
  background: #2f91ae;
  display: flex;
  width: 196px;
  height: 49px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  @media screen and (max-width: 1535px) {
    width: 180px;
    gap: 9px;
    font-size: 23px;
    height: 45px;
  }

  @media screen and (max-width: 1279px) {
    width: 160px;
    gap: 8px;
    font-size: 22px;
    height: 41px;
  }

  @media screen and (max-width: 1023px) {
    width: 140px;
    gap: 7px;
    font-size: 20.5px;
    height: 37px;
  }

  @media screen and (max-width: 767px) {
    width: 120px;
    gap: 6px;
    font-size: 19px;
    height: 33px;
  }

  @media screen and (max-width: 639px) {
    width: 100px;
    gap: 5px;
    font-size: 17.5px;
    height: 30px;
  }

  @media screen and (max-width: 430px) {
    width: 90px;
    gap: 4px;
    font-size: 16px;
    height: 27px;
  }
}

.viewButton {
  color: #2f91ae;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  line-height: normal;
  text-decoration-line: underline;

  @media screen and (max-width: 1535px) {
    font-size: 23px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 22px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 20.5px;
  }

  @media screen and (max-width: 767px) {
    font-size: 19px;
  }

  @media screen and (max-width: 639px) {
    font-size: 17.5px;
  }

  @media screen and (max-width: 430px) {
    font-size: 16px;
  }
}

.menuItem {
  color: #000;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  cursor: pointer;

  @media screen and (max-width: 639px) {
    font-size: 13px;
  }
}

.morebutton {
  width: 228px;
  height: 37px;
  padding: 10px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #2f91ae;
  color: #2f91ae;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
