.collaborationSpace {
  background-color: #b3d8df;
  padding-top: 80px;
  padding-bottom: 112px;

  @media screen and (max-width: 639px) {
    padding-top: 34px;
    padding-bottom: 63px;
  }
}

.title {
  color: #000;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;

  @media screen and (max-width: 639px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
  @media screen and (max-width: 393px) {
    font-size: 24px;
  }


  @media screen and (max-width: 1600px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 36px;
  }

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

