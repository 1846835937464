.title {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 40px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;

  @media screen and (max-width: 1535px) {
    font-size: 34px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 28px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 21px;
  }

  @media screen and (max-width: 767px) {
    font-size: 32px;
  }

  @media screen and (max-width: 639px) {
    font-size: 26px;
  }

  @media screen and (max-width: 430px) {
    font-size: 20px;
  }
}

.productLocation {
  color: #898989;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;
  text-decoration-line: underline;
  cursor: pointer;
}

.subTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.body1 {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
  line-height: 24px;
}

.content {
  color: #212121;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 24px;
}

.viewMore {
  color: #2f91ae;
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
}

.contactButton {
  display: flex;
  width: 573px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #2f91ae;
  color: #fff;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  @media screen and (max-width: 1535px) {
    width: 520px;
  }

  @media screen and (max-width: 1279px) {
    width: 450px;
  }

  @media screen and (max-width: 1023px) {
    width: 330px;
  }

  @media screen and (max-width: 767px) {
    width: 500px;
  }

  @media screen and (max-width: 639px) {
    width: 360px;
  }

  @media screen and (max-width: 430px) {
    width: 302px;
  }
}
