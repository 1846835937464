.formTitle {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Brandon Grotesque';
  font-size: 36px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;
  margin-bottom: 23px;

  @media screen and (max-width: 1535px) {
    font-size: 43px;
    line-height: 45px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 38px;
    line-height: 41px;
    margin-bottom: 17px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 33px;
    line-height: 38px;
    margin-bottom: 14px;
  }

  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 11px;
  }

  @media screen and (max-width: 639px) {
    font-size: 24px;
    line-height: 31.505px;
    margin-bottom: 8px;
  }
}

.formLabel {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 390;
  line-height: 40px;

  @media screen and (max-width: 1535px) {
    font-size: 18.5px;
    line-height: 37px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 17px;
    line-height: 34px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 15.5px;
    line-height: 31px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 28px;
  }

  @media screen and (max-width: 639px) {
    font-size: 13.127px;
    line-height: 26.254px;
  }
}

.errorInput {
  border: 1px solid rgb(238, 40, 40);
  background: #fff;
  height: 48px;

  @media screen and (max-width: 1535px) {
    height: 44.5px;
  }

  @media screen and (max-width: 1279px) {
    height: 41px;
  }

  @media screen and (max-width: 1023px) {
    height: 37.5px;
  }

  @media screen and (max-width: 767px) {
    height: 34px;
  }

  @media screen and (max-width: 639px) {
    height: 31.505px;
  }
}

.formInput {
  border: 1px solid #6f6851;
  background: #fff;
  height: 48px;

  @media screen and (max-width: 1535px) {
    height: 44.5px;
  }

  @media screen and (max-width: 1279px) {
    height: 41px;
  }

  @media screen and (max-width: 1023px) {
    height: 37.5px;
  }

  @media screen and (max-width: 767px) {
    height: 34px;
  }

  @media screen and (max-width: 639px) {
    height: 31.505px;
  }
}

.errorTextArea {
  border: 1px solid rgb(238, 40, 40);
  background: #fff;
  height: 128px;

  @media screen and (max-width: 639px) {
    height: 84.013px;
  }
}

.formTextArea {
  border: 1px solid #6f6851;
  background: #fff;
  height: 128px;

  @media screen and (max-width: 639px) {
    height: 84.013px;
  }
}

.formButton {
  border-radius: 100px;
  background: #2f91ae;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10.5px 0px;
  justify-content: center;
  align-items: center;
  display: flex;

  @media screen and (max-width: 1535px) {
    border-radius: 93px;
    padding: 10px 0px;
    font-size: 22px;
  }

  @media screen and (max-width: 1279px) {
    border-radius: 86px;
    padding: 9px 0px;
    font-size: 20px;
  }

  @media screen and (max-width: 1023px) {
    border-radius: 79px;
    padding: 8px 0px;
    font-size: 18px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 72px;
    padding: 7px 0px;
    font-size: 16px;
  }

  @media screen and (max-width: 639px) {
    border-radius: 65.635px;
    padding: 6.564px 0px;
    font-size: 15.752px;
  }
}

.contactEmail {
  color: #000;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-left: 20px;
  margin-top: 30px;

  @media screen and (max-width: 1535px) {
    font-size: 15px !important;
    line-height: 38px !important;
    margin-left: 10px !important;
  }

  @media screen and (max-width: 1279px) {
    font-size: 14px !important;
    line-height: 36px !important;
    margin-left: 10px !important;
  }

  @media screen and (max-width: 1023px) {
    font-size: 13px !important;
    line-height: 34px !important;
    margin-left: 20px !important;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px !important;
    line-height: 32px !important;
    margin-left: 30px !important;
  }

  @media screen and (max-width: 639px) {
    font-size: 11px !important;
    line-height: 30px !important;
    margin-left: 30px !important;
  }

  @media screen and (max-width: 430px) {
    font-size: 10.502px !important;
    line-height: 26.254px !important;
    margin-left: 16px !important;
  }
}

.contactTel {
  color: #000;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 30px;

  @media screen and (max-width: 1535px) {
    font-size: 15px !important;
    line-height: 38px !important;
    margin-right: 10px !important;
  }

  @media screen and (max-width: 1279px) {
    font-size: 14px !important;
    line-height: 36px !important;
    margin-right: 10px !important;
  }

  @media screen and (max-width: 1023px) {
    font-size: 13px !important;
    line-height: 34px !important;
    margin-right: 20px !important;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px !important;
    line-height: 32px !important;
    margin-right: 30px !important;
  }

  @media screen and (max-width: 639px) {
    font-size: 11px !important;
    line-height: 30px !important;
    margin-right: 24px !important;
  }

  @media screen and (max-width: 430px) {
    font-size: 10.502px !important;
    line-height: 26.254px !important;
    margin-right: 32px !important;
  }
}
