.sliderArea {
  background: linear-gradient(to bottom, #f4f7f9 30.18%, #fff 75.18%);
}

.title {
  color: #000;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 36px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;

  @media screen and (max-width: 639px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.cardInfo {
  border-radius: 0px 0px 6px 6px;
  background: #e1f0f3;
  padding: 16px 22.37px 24px;

  @media screen and (max-width: 1023px) {
    padding: 8px 14px;
  }

  @media screen and (max-width: 767px) {
    padding: 6px 12px;
  }

  @media screen and (max-width: 430px) {
    padding: 4.87px 8.04px;
  }
}

.subtitle {
  color: #2f91ae;
  font-family: 'Brandon Grotesque';
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 33.484px;

  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 24.5px;
  }

  @media screen and (max-width: 767px) {
    font-size: 11px;
    line-height: 19.25px;
  }

  @media screen and (max-width: 430px) {
    font-size: 7.106px;
    line-height: 12.601px;
  }
}

.viewProduct {
  color: #000;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 22px;
  font-style: normal;
  font-weight: 280;
  line-height: 18.177px;

  @media screen and (max-width: 1023px) {
    font-size: 18px;
    line-height: 14.04px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 10.92px;
  }

  @media screen and (max-width: 430px) {
    font-size: 8.641px;
    line-height: 6.84px;
  }
}
