.title {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 48px;
  font-style: normal;
  font-weight: 390;
  line-height: 24px;

  @media screen and (max-width: 1535px) {
    font-size: 44px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 40px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 36px;
  }

  @media screen and (max-width: 767px) {
    font-size: 32px;
  }

  @media screen and (max-width: 639px) {
    font-size: 28px;
  }

  @media screen and (max-width: 430px) {
    font-size: 24px;
  }
}
