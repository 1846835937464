.title {
  color: #000;
  text-align: center;
  font-family: 'Brandon Grotesque';
  font-size: 36px;
  font-style: normal;
  font-weight: 390;
  line-height: 48px;
  margin-bottom: 38px;

  @media screen and (max-width: 1535px) {
    font-size: 36px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 40px;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 36px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    font-size: 32px;
    margin-bottom: 14px;
  }

  @media screen and (max-width: 639px) {
    font-size: 24px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 430px) {
    font-size: 24px;
    margin-bottom: 2px;
  }
}
