.title {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 96px;
  font-style: normal;
  font-weight: 450;
  line-height: 159px;
  padding-left: 0px;
  display: flex;

  @media screen and (max-width: 1535px) {
    font-size: 87px !important;
    line-height: 139px !important;
  }

  @media screen and (max-width: 1279px) {
    font-size: 78px !important;
    line-height: 119px !important;
  }

  @media screen and (max-width: 1023px) {
    font-size: 69px !important;
    line-height: 99px !important;
  }

  @media screen and (max-width: 767px) {
    font-size: 60px !important;
    line-height: 79px !important;
  }

  @media screen and (max-width: 639px) {
    font-size: 50px !important;
    line-height: 59px !important;
    padding-left: 20px !important;
    margin-bottom: 21px;
    display: block;
  }

  @media screen and (max-width: 430px) {
    font-size: 40px !important;
    line-height: 38px !important;
  }
}

.bottomTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  line-height: 89px;
  padding-left: 0px;
  display: flex;

  @media screen and (max-width: 1535px) {
    font-size: 23px !important;
    line-height: 79px !important;
  }

  @media screen and (max-width: 1279px) {
    font-size: 22px !important;
    line-height: 69px !important;
  }

  @media screen and (max-width: 1023px) {
    font-size: 21px !important;
    line-height: 59px !important;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px !important;
    line-height: 49px !important;
    display: block;
  }

  @media screen and (max-width: 639px) {
    font-size: 18px !important;
    line-height: 20px !important;
    padding-left: 20px !important;
  }

  @media screen and (max-width: 430px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}
