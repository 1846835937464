.contactSpace {
  background-image: url('/public/images/bg1.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 975px;

  @media screen and (max-width: 639px) {
    background-image: unset;
    background: #fff;
    height: 467px;
  }
}

.form {
  width: 614px;
  height: 712px;
  border-radius: 24px;
  background: #fff;
  padding: 54px 93px 54px 74px;
  margin-left: 124px;

  @media screen and (max-width: 1535px) {
    margin-left: 90px;
  }

  @media screen and (max-width: 1279px) {
    margin-left: 80px;
  }

  @media screen and (max-width: 1023px) {
    margin-left: 70px;
  }

  @media screen and (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 639px) {
    padding: 42px 61px 35px 42px;
    width: 100%;
    height: 467px;
  }
}
