.menuArea {
  background-color: #f4f7f9;
}

.topBg {
  width: 100%;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: background-image 0.5s ease-in-out;
  height: 799px;

  @media screen and (max-width: 639px) {
    height: 365px;
    top: 0px;
    left: 0px;
  }
}

.menuItem {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 18px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;
  cursor: pointer;
}
