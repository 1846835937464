.introArea {
  background-color: #f4f7f9;
}

.topTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px; /* 200% */
  margin-bottom: 15px;
  padding-left: 0px;

  @media screen and (max-width: 639px) {
    font-size: 16px !important;
    margin-bottom: 0px !important;
    padding-left: 20px !important;
  }
}

.middleTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 96px;
  font-style: normal;
  font-weight: 450;
  line-height: 70px;
  padding-left: 0px;

  @media screen and (max-width: 639px) {
    font-size: 40px !important;
    line-height: 38px !important;
    padding-left: 20px !important;
  }
}

.bottomTitle {
  color: #000;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  line-height: 48px;
  padding-left: 0px;

  @media screen and (max-width: 639px) {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-left: 20px !important;
  }
}

.contactButton {
  width: 212px;
  height: 54px;
  border-radius: 100px;
  background: #2f91ae;
  color: #fff;
  font-family: 'Brandon Grotesque';
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-left: 0px;

  @media screen and (max-width: 639px) {
    width: 164px !important;
    height: 43px !important;
    font-size: 16px !important;
    margin-left: 12px !important;
  }
}
