.card {
  background-color: white;
  border: 1px solid #d1cdcd;
}

.status {
  background: #2f91ae;
  top: -46px;
  left: 0px;
  color: #fff;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media screen and (max-width: 1535px) {
    font-size: 11px;
    line-height: 22px;
    top: -44px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 10px;
    line-height: 20px;
    top: -42px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 9px;
    line-height: 18px;
    top: -40px;
  }

  @media screen and (max-width: 767px) {
    font-size: 8px;
    line-height: 16px;
    top: -38px;
  }

  @media screen and (max-width: 639px) {
    font-size: 7px;
    line-height: 14px;
    top: -36px;
  }

  @media screen and (max-width: 430px) {
    font-size: 6.308px;
    line-height: 12.615px;
    top: -34px;
  }
}

.productName {
  color: #252b42;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;

  @media screen and (max-width: 1535px) {
    font-size: 14.5px;
    line-height: 22px;
    letter-spacing: 0.095px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.09px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 11.5px;
    line-height: 18px;
    letter-spacing: 0.08px;
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.07px;
  }

  @media screen and (max-width: 639px) {
    font-size: 9px;
    line-height: 14px;
    letter-spacing: 0.06px;
  }

  @media screen and (max-width: 430px) {
    font-size: 8.41px;
    line-height: 12.615px;
    letter-spacing: 0.053px;
  }
}

.brandName {
  color: #737373;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;

  @media screen and (max-width: 1535px) {
    font-size: 13px;
    line-height: 18.5px;
    letter-spacing: 0.18px;
  }

  @media screen and (max-width: 1279px) {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.165px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 11px;
    line-height: 15.5px;
    letter-spacing: 0.15px;
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.135px;
  }

  @media screen and (max-width: 639px) {
    font-size: 9px;
    line-height: 12.5px;
    letter-spacing: 0.12px;
  }

  @media screen and (max-width: 430px) {
    font-size: 7.359px;
    line-height: 10.513px;
    letter-spacing: 0.105px;
  }
}

.detailButton {
  border-radius: 37px;
  border: 1px solid #000;
  color: #000;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.2px;

  @media screen and (max-width: 1535px) {
    border-radius: 34px;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.18px;
  }

  @media screen and (max-width: 1279px) {
    border-radius: 31px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.165px;
  }

  @media screen and (max-width: 1023px) {
    border-radius: 28px;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.15px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 25px;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.135px;
  }

  @media screen and (max-width: 639px) {
    border-radius: 22px;
    font-size: 9px;
    line-height: 14px;
    letter-spacing: 0.12px;
  }

  @media screen and (max-width: 430px) {
    border-radius: 19.449px;
    font-size: 7.359px;
    line-height: 12.615px;
    letter-spacing: 0.105px;
  }
}
